import React from "react";
import Layout from "components/layout";
import InsiderComp from "components/tomedesInsider";
import SEO from "components/seo";

function index() {
  const title = "Tomedes Insider - Translation Industry Insights | Tomedes";
  const description =
    "Best Translation Blog - Language and translation industry insights from linguists, professional translators and Tomedes experts.";
  const keywords = "translation, translation service, document translation";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/tomedes-insider"
      />
      <InsiderComp />
    </Layout>
  );
}

export default index;
